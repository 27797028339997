import React, {useState, useEffect} from "react";
import './ComingSoon.css';
import logo from "../images/gear.svg"
import Countdown from "../components/countdown/Countdown";
import {Logo} from "../components/logo/Logo";
import {Title} from "../components/title/Title"
import {Description} from "../components/description/Description"
import Subscribe from "../components/subscribe/Subscribe";
import Footer from "../components/footer/Footer";

const ComingSoon = () => {
    const [domainName, setDomainName] = useState("");
    const [config, setConfig] = useState({
        countdownDate: "",
        title: "",
        subscribePlaceholder: "",
        subscribeButtonText: "",
        social: {
            github: "",
            linkedin: "",
            website: ""
        }
    });

    useEffect(() => {
        setDomainName(window.location.hostname);

        // Load configuration
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error loading config:', error));
    }, []);

    return (
        <div className="background">
            <div className="content">
                <Countdown futureDate={config.countdownDate}/>
                <Logo alt="Spinning gear" src={logo}/>
                <Title text={config.title}/>
                <Description domainName={domainName}/>
                <Subscribe placeholder={config.subscribePlaceholder} buttonText={config.subscribeButtonText}/>
            </div>
            <Footer social={config.social}/>
        </div>
    );
}

export default ComingSoon;
