import React, {Component} from "react";
import "./Subscribe.css";

class Subscribe extends Component {
    state = {
        email: "",
        showPopup: false
    };

    handleChange = (e) => {
        this.setState({email: e.target.value.trim()});
    };

    handlePopup = () => {
        this.setState({showPopup: true}, () => {
            setTimeout(() => {
                this.setState({showPopup: false});
            }, 3000);
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let apiBaseUrl = process.env.REACT_APP_API_BASE_URL
        if (apiBaseUrl === undefined) {
            console.log("API base URL not found in environment variable, using default")
            apiBaseUrl = "https://comingsoonapi.adamspierredavid.com"
        }
        const domainName = window.location.hostname;
        if (this.state.email) {
            fetch(`${apiBaseUrl}/api/v1/emails/collect`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    website: domainName,
                    email: this.state.email
                })
            })
                .then((res) => {
                    if (res.status === 409) {
                        return res.json().then((data) => {
                            console.log(data.message);
                            this.setState({popupMessage: "Email already exists."});
                            this.handlePopup();
                        });
                    } else if (res.status === 201) {
                        return res.json().then((data) => {
                            console.log(data.message);
                            this.setState({popupMessage: "Thank you for subscribing!"});
                            this.handlePopup();
                            this.setState({email: ""});
                        });
                    } else {
                        throw new Error("Unexpected response status");
                    }
                })
                .catch((err) => console.log(err));
        }
    };


    render() {
        const {placeholder, buttonText} = this.props;
        return (
            <div>
                {this.state.showPopup && (
                    <div className="popup">{this.state.popupMessage}</div>
                )}
                <form onSubmit={this.handleSubmit} className="subscribe">
                    <input
                        className="subscribe-email"
                        name="email"
                        type="email"
                        placeholder={placeholder}
                        onChange={this.handleChange}
                        value={this.state.email}
                    />
                    <button
                        className="subscribe-button"
                        type="submit"
                    >
                        {buttonText}
                    </button>
                </form>
            </div>
        );
    }
}

export default Subscribe;
