import React, {useState, useEffect, useCallback} from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import "./Countdown.css";

const Countdown = ({futureDate}) => {
    // State to hold the time left until the future date
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        mins: 0,
        secs: 0
    });
    // State to track if the countdown has finished
    const [isFinished, setIsFinished] = useState(false);

    // Memoized function to calculate time left
    const calculateTimeLeft = useCallback(() => {
        const futureTime = moment(futureDate);
        const now = moment();

        // Check if the provided date is valid
        if (!futureTime.isValid()) {
            console.error("Invalid future date provided");
            setIsFinished(true);
            return {days: 0, hours: 0, mins: 0, secs: 0};
        }

        const duration = moment.duration(futureTime.diff(now));

        // Check if the countdown has finished
        if (duration.asSeconds() <= 0) {
            setIsFinished(true);
            return {days: 0, hours: 0, mins: 0, secs: 0};
        }

        // Calculate and return the time left
        return {
            days: Math.floor(duration.asDays()),
            hours: duration.hours(),
            mins: duration.minutes(),
            secs: duration.seconds()
        };
    }, [futureDate]);

    // Effect to update the countdown every second
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        // Cleanup function to clear the interval
        return () => clearInterval(timer);
    }, [calculateTimeLeft]);

    // Render the finished state if the countdown is complete
    if (isFinished) {
        return <div className="countdown-finished">Countdown finished!</div>;
    }

    // Render the countdown
    return (
        <div className="countdown" role="timer" aria-live="polite">
            {Object.entries(timeLeft).map(([key, value]) => (
                <div className="countdown-segment" key={key}>
                    <span className="countdown-segment-number" aria-label={`${value} ${key}`}>
                        {value.toString().padStart(2, '0')}
                    </span>
                    <span className="countdown-segment-caption">
                        {key.toUpperCase()}
                    </span>
                </div>
            ))}
        </div>
    );
};

Countdown.propTypes = {
    futureDate: PropTypes.string.isRequired,
};

export default Countdown;
