import React from 'react';
import {Github, Linkedin, Globe} from 'lucide-react';
import './Footer.css';

const socialIcons = {
    github: Github,
    linkedin: Linkedin,
    website: Globe
};

const Footer = ({social}) => {
    return (
        <footer className="footer">
            <div className="footer-content">
                {Object.entries(social).map(([key, value]) => {
                    if (value) {
                        const Icon = socialIcons[key];
                        return (
                            <a
                                key={key}
                                href={value}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-link"
                            >
                                <Icon size={24}/>
                            </a>
                        );
                    }
                    return null;
                })}
            </div>
        </footer>
    );
};

export default Footer;
