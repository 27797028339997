import React, {useState, useEffect, useCallback} from "react";
import "./Description.css";

export const Description = ({domainName}) => {
    const [config, setConfig] = useState(null);
    const [message, setMessage] = useState("");

    const updateMessage = useCallback((cfg) => {
        const isApologyNeeded = cfg.apologySites.includes(domainName);
        const template = isApologyNeeded ? cfg.apologyMessage : cfg.comingSoonMessage;
        setMessage(template.replace("{domainName}", domainName));
    }, [domainName]);

    useEffect(() => {
        fetch("/config.json")
            .then(response => response.json())
            .then(data => {
                setConfig(data);
                updateMessage(data);
            })
            .catch(error => console.error("Error loading config:", error));
    }, [updateMessage]);

    useEffect(() => {
        if (config) {
            updateMessage(config);
        }
    }, [config, updateMessage]);

    if (!config) {
        return <div className="description">Loading...</div>;
    }

    return (
        <div className="description">
            <p className="description-text">{message}</p>
        </div>
    );
};
